<template>
  <div v-if="!loading2">
    <!-- <div v-show="!loading"> -->
    <v-tabs v-model="tab" hide-slider v-if="!loading">
      <v-tab v-for="item in computedTabsList" :key="item.tab" class="text-h6">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-progress-linear v-else
      indeterminate
    ></v-progress-linear>

    <v-divider color="#512DA8" class="mb-3"></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <MainPersonalData />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="userInfo.bussiness == true">
        <v-card flat>
          <MainCompanyData />
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-if="
          userInfo.bussiness == true && userInfo.showContactPersons == true
        "
      >
        <v-card flat>
          <MainContactPersons />
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-if="userInfo.bussiness == true && userInfo.showCars == true"
      >
        <v-card flat>
          <MainCars />
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item>
          <v-card flat>
            <MainDrivers />
          </v-card>
        </v-tab-item> -->
    </v-tabs-items>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MainPersonalData from "@/components/app/MySpace/PersonalData/MainPersonalData.vue";
import MainCompanyData from "@/components/app/MySpace/CompanyData/MainCompanyData.vue";
import MainContactPersons from "@/components/app/MySpace/ContactPersons/MainContactPersons.vue";
import MainCars from "@/components/app/MySpace/Cars/MainCars.vue";
// import MainDrivers from "@/components/app/MySpace/Drivers/MainDrivers.vue";
export default {
  name: "MySpace",
  data: () => ({
    loading2: true,
    user: null,
    tab: null,
    items: [
      { tab: "פרטים אישיים", content: "Tab 1 Content", bussiness: "both" },
      {
        tab: "פרטי החברה",
        content: "Tab 2 Content",
        bussiness: true,
        infoLabel: "anytime",
      },
      {
        tab: "אנשי קשר",
        content: "Tab 3 Content",
        bussiness: true,
        infoLabel: "showContactPersons",
      },
      {
        tab: "רכבים",
        content: "Tab 4 Content",
        bussiness: true,
        infoLabel: "showCars",
      },
      // { tab: "נהגים", content: "Tab 5 Content" },
    ],
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
    computedTabsList() {
      let computedItems = [];
      if (!this.loading2) {
        if (this.userInfo != null) {
          if (this.userInfo != undefined) {
            this.items.forEach((item) => {
              console.log("item", item);
              if (this.userInfo.bussiness === undefined)
                this.userInfo.bussiness = false;
              if (item.bussiness === "both") {
                computedItems.push(item);
              }
              if (!this.loading) {
                if (item.bussiness === this.userInfo.bussiness) {
                  if (item.infoLabel === "anytime") {
                    computedItems.push(item);
                  } else {
                    if (this.userInfo[item.infoLabel] === undefined) {
                      computedItems.push(item);
                    } else {
                      if (this.userInfo[item.infoLabel] === true) {
                        computedItems.push(item);
                      }
                    }
                  }
                }
              }
            });
          }
        }
      }
      return computedItems;
    },
  },
  async created() {
    // await this.$store.dispatch("loading", true);
    // this.user = await this.$store.dispatch("getUserFullByID", {
    //   uid: this.UID,
    // });
    await this.$store.dispatch("getUserInfo");
    console.log("user", this.user);
    this.loading2 = false;
    // await this.$store.dispatch("loading", false);
  },
  methods: {},
  components: {
    MainPersonalData,
    MainCompanyData,
    MainContactPersons,
    MainCars,
    // MainDrivers,
  },
  // components: {
  // },
};
</script>

<style scoped>
.v-tab--active {
  border: #1565c0;
  background-color: #1565c0;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  /* border-radius: 15px 15px 0px 0px; */
  color: white !important;
}
.v-divider {
  border-top-width: 1px;
}
</style>
