<template>
  <v-container flex>
    <v-row>
      <v-col col-6>
        <AddOneObjectModal
          :title="'איש קשר חדש'"
          :buttonLabel="'הוסף איש קשר חדש'"
          :addingFunction="'AddContactPerson'"
          :fieldsGetter="'contactPersonsNewFields'"
          :fieldsGetFunctionName="'contactPersonsNewFields'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="5"
        lg="5"
        md="12"
        sm="12"
        cols="12"
        v-for="(person, index) in contactPersons"
        :key="`${person.id}`"
      >
        <ShowOneObject
          :id="person.id"
          :title="`איש קשר ${index + 1}`"
          :fieldsGetter="'contactPersonsFields'"
          :fieldsGetFunctionName="'contactPersonsFields'"
          :getFieldValuesFunc="'contactPerson'"
          :updateFieldValuesFunc="'updateContactPerson'"
          :removeFieldValuesFunc="'removeContactPerson'"
          :addFieldValuesFunc="'AddContactPerson'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ShowOneObject from "@/components/app/MySpace/components/ShowOneObject";
import AddOneObjectModal from "@/components/app/MySpace/components/AddOneObjectModal";
export default {
  name: "MainContactPersons",
  data: () => ({
    addDialog: false,
  }),
  methods: {},
  components: {
    ShowOneObject,
    AddOneObjectModal,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("getContactPersons");
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading", "contactPersons"]),
  },
};
</script>
