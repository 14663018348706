<template>
    <v-container flex>
      <v-row>
        <v-col xl="5" lg="5" md="12" sm="12" cols="12" >
          <PersonalData />
          <AuthData />
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <Notifications />
        </v-col> -->
      </v-row>
    </v-container>
</template>

<script>
import PersonalData from "@/components/app/MySpace/PersonalData/components/PersonalData";
import AuthData from "@/components/app/MySpace/PersonalData/components/AuthData.vue";
// import Notifications from "@/components/app/MySpace/PersonalData/components/Notifications.vue";
export default {
  name: "Home",
  data: () => ({
  }),
  methods: {},
  components: {
    PersonalData,
    AuthData,
    // Notifications,
  }
};
</script>
